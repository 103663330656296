.background {
    overflow-x: hidden;
    background-color:#FFFFFF;
}

.results-background {
    width: 100vw;
    min-height: calc(100vh + 2rem);
    margin-bottom: -2rem;
    padding: 1rem;
    gap: 2rem;
    display: grid;
    grid-template-rows: auto auto 1fr;
    position: relative;
}

.results-background-public {
    width: 100vw;
    padding: 1rem;
}

.results-dropdown-title {
    font-size: 16pt;
    font-weight: 600;
    margin-bottom: 4px;
}

.wire {
    height: 5px;
    position: absolute;
    left: 0;
    width: 92px;
    background-color: #DA291C;
    top: calc(144px + 52px + 35px);
    z-index: 10
}

.wire-public {
    height: 5px;
    position: absolute;
    left: 0;
    width: 92px;
    background-color: #DA291C;
    top: calc(100px + 52px + 35px);
    z-index: 10
}

.plug {
    position: absolute;
    height: 30px;
    z-index: 11;
    top: calc(144px + 52px + 23px);
    left: 88px;
    background-color: #F5DCD7;
    padding: 2px 0px
}

.plug-public {
    position: absolute;
    height: 30px;
    z-index: 11;
    top: calc(100px + 52px + 23px);
    left: 88px;
    background-color: #F5DCD7;
    padding: 2px 0px
}

.results-dropdown-element{
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 1rem;
}

.results-dropdown {
    width: 420px;
    height: 50px;
    display: flex;
    align-items: center;
    background-color: #FFFFFF;
    border-radius: 10px;
    padding: 0px 20px;
    font-size: 1.1vw;
    cursor: pointer;
    border: 2px solid transparent;
    transition: all 0.1s ease-out;
    margin-bottom: 10px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    position: relative;
}

.results-dropdown-selected {
    width: 420px;
    height: 50px;
    display: flex;
    align-items: center;
    background-color: #FFFFFF;
    border-radius: 10px;
    padding: 0px 20px;
    font-size: 1.1vw;
    cursor: pointer;
    border: 2px solid rgba(218, 41, 28, 0.5);
    transition: all 0.1s ease-out;
    margin-bottom: 10px;
    box-shadow: rgba(218, 41, 28, 0.4) 0px 2px 5px -1px, rgba(218, 41, 28, 0.5) 0px 1px 3px -1px;
}

.results-dropdown:hover {
    border: 2px solid rgba(218, 41, 28, 0.5)
}

.results-dropdown-arrow {
    width: 16px;
    position: absolute;
    margin-left: 360px;
    transition: all 0.2s ease-out;
}

.results-dropdown-arrow-up {
    width: 16px;
    position: absolute;
    margin-left: 360px;
    rotate: 180deg;
    transition: all 0.2s ease-out;
}

.results-dropdown-container-hidden {
    height: 0;
    transition: height 0.4s ease-out;
    border-radius: 10px;
}

.results-dropdown-container-visible {
    min-height: 320px;
    transition: height 0.4s ease-out;
    background-color: white;
    border-radius: 10px;
    padding: 1rem;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.results-dropdown-content-hidden {
    display: none;
}

.results-dropdown-content-visible {
    display: flex;
    flex-direction: column;
}

.results-dropdown-subtitle {
    font-size: 1.2vw;
    font-weight: 500;
    margin-bottom: 12px;
}

.results-dropdown-draw-boxes-container {
    display: flex;
    margin-bottom: 20px;
    gap: 20px;
    flex-wrap: wrap;
}

.results-table {
    border-radius: 20px;
    background-color: #FFFFFF;
    margin-top: 20px;
    width: 80vw;
    border-spacing: 0;
}

.results-table th {
    padding: 20px 32px;
    font-size: 16px;
    font-weight: 600;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1)
}

.results-table td {
    padding: 18px 38px;
    text-align: center;
}

.results-table tr {
    border-radius: 10px;
    font-size: 1.025vw;
    letter-spacing: 0.5px;
}

.results-table tbody tr:hover {
    background-color: #fbfbfb;
}

.results-table td:first-child {
    border-top-left-radius: 10px; 
    border-bottom-left-radius: 10px;
}
.results-table td:last-child {
    border-bottom-right-radius: 10px; 
    border-top-right-radius: 10px; 
    width: 30vw;
}

.table-you-win-row {
    background-color: rgba(0,195,156, 0.2);
    font-weight: 600;
}

.table-you-win-row:hover {
    background-color: rgba(0,195,156, 0.2) !important;
    filter: brightness(1.1);
    cursor: pointer;
}

@media only screen and (max-width: 600px) {

    .results-title {
        font-size: 1.6rem;
        margin-bottom: 2vh;
    }

    .wire {
        display: none;
    }

    .wire-public {
        display: none;
    }

    .plug {
        display: none;
    }

    .plug-public {
        display: none;
    }

    .results-dropdown-title {
        font-weight: 600;
        margin-bottom: 4px;
    }
    
    .results-dropdown {
        width: 90vw;
        height: 48px;
        font-size: 4.8vw;
    }
    
    .results-dropdown-selected {
        width: 90vw;
        height: 48px;
        font-size: 4.8vw;
    }

    .results-dropdown-arrow {
        margin-left: 74vw;
    }
    
    .results-dropdown-arrow-up {
        margin-left: 74vw;
    }

    .results-table-search {
        width: 90vw;
        height: 44px;
        border: 1px solid #cfcfc4;
        border-radius: 10px;
        padding: 10px 50px;
        font-weight: 500;
        letter-spacing: 1px;
    }

    .results-table-close-icon {
        margin: 14px 80vw;
    }

    .results-table th {
        padding: 18px 16px;
    }
    
    .results-table td {
        padding: 12px 16px;
        text-align: center;
        font-size: 4vw;
    }

    .results-dropdown-subtitle {
        font-size: 5vw;
        font-weight: 500;
        margin-bottom: 12px;
    }    

    .results-table {
        width: 100%;
    }
    
    .results-table-container{
        padding: 2rem 1rem;
        border-radius: 30px 30px 0 0;
        background-color: var(--color-yellow);
        margin: 0 -1rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
}
