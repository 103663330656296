.date-input {
    border: none;
    outline: none;
    cursor: pointer;
    font-family: "Rubik", sans-serif;
    font-size: 1.05vw;
    border-bottom: 1px solid #D3D3D3;
}

.date-input-disabled {
    border: none;
    outline: none;
    font-family: "Rubik", sans-serif;
    font-size: 1.05vw;
    background-color: #FFFFFF;
    text-align: center;
}

::-webkit-calendar-picker-indicator{
    cursor: pointer;
    padding-right: 10px;
}
