.navbar {
  width: 100vw;
  height: 100px;
  background-color: white;
  box-shadow: 0px 3px 6px #00000029;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;

  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 500;
}
  
.navbar-logo {
  height: 50px;
  cursor: pointer;
  align-items: center;
}

.navbar-item {
  color: var(--color-orange);
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  border-radius: 5px;
}

.navbar-item .navbar-icon {
  width: 30px;
  height: 30px;
}
  
.navbar-item.selected {
  color: #EB1B21;
  font-weight: 700;
  background-color: rgb(249, 213, 165);
}

.navbar-item.warning {
  border: 1px solid var(--color-orange);
}

.navbar-item .notification {
  position: absolute;
  right: -25px;
  top: 0;
  transform: translateX(-50%) translateY(-50%);
  width: 30px;
  height: 30px;

  &:before {
    content: '';
    position: relative;
    display: block;
    width: 300%;
    height: 300%;
    box-sizing: border-box;
    margin-left: -100%;
    margin-top: -100%;
    border-radius: 45px;
    background-color: var(--color-orange);
    animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: var(--color-orange);
    border-radius: 15px;
    box-shadow: 0 0 8px rgba(0, 0, 0, .3);
    animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -.4s infinite;
  }
}

@keyframes pulse-ring {
  0% {
    transform: scale(.33);
  }

  80%,
  100% {
    opacity: 0;
  }
}

@keyframes pulse-dot {
  0% {
    transform: scale(.8);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(.8);
  }
}