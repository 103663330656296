.draw-box-container {
    width: 300px;
    background-color: rgba(218, 41, 28, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 12px;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    border: 1px solid #FFFFFF
}

.draw-box-container:hover {
    background-color: rgba(218, 41, 28, 0.2);
}

.draw-box-container-disabled {
    width: 300px;
    background-color: #D3D3D3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 12px;
    border-radius: 10px;
    cursor: default;
    opacity: 0.5;
}

.draw-box-container-selected {
    width: 300px;
    background-color: rgba(218, 41, 28, 0.4);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 12px;
    border-radius: 10px;
    cursor: default;
    border: 1px solid rgba(218, 41, 28, 0.8);
}


.draw-box-title {
    font-size: 1.2rem;
    margin-bottom: 6px;
    font-weight: 500;
}
