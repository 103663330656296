.download-model-container {
    display: flex;
    align-items: center;
    width: fit-content;
    gap: 14px;
    cursor: pointer;
}

.excel-icon {
    width: 20px;
}

.download-model-label {
    font-size: 16px;
    font-family: 'Rubik', sans-serif;
    background: linear-gradient(90deg, #2e7d32 calc(100%/3), transparent 0 calc(200%/3), #2e7d32 0) var(--d, 0%) 100% /300% 1.5px no-repeat;
    transition: 0.5s;
}

.download-model-container:hover .download-model-label {
    --d: 100%;
    transition: 0.5s;
    color: #2e7d32;
    transform: scale(1.02);
    filter: brightness(1.05)
}