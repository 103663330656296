.signup-page-container {
    display: flex;
    width: 100%;
    min-height: calc(100vh - 100px);
}

.signup-container {
    width: 100%;
    background-color:white;
    display: flex;
    flex-direction: column;
    gap: 2.5vh;
    justify-content: space-between;
}


.signup-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 30px 30px 0 0;
    padding: 3rem 2rem 5rem 2rem;
    margin-bottom: -1rem;
    position: relative;
    background-color: var(--color-yellow);
    gap: 3rem;
}

.signup-form-row {
    display: flex;
    gap: 20px;
}

.signup-form-title {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 2.4vw;
    text-align: center;
    color: #FFFFFF;
}

.signup-form-subtitle {
    font-size: 1.35vw;
    font-weight: 400;
    text-align: center;
    color: #FFFFFF;
    margin: -2px 0px 24px 0px;
}

.signup-form-invalid-cpf-border {
    padding: 4px;
    width: fit-content;
    height: fit-content;
    margin-bottom: 32px;
    border-radius: 22px;
    border: 3px solid #EB1B21;
}

.signup-form-invalid-cpf-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    background-color: #F8CCCA;
    padding: 20px 56px;
    border-radius: 16px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.signup-form-invalid-cpf-warning-container {
    background-color: #DA291C;
    padding: 10px 23px;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.signup-form-invalid-cpf-warning-text {
    text-transform: uppercase;
    color: #FFFFFF;
    font-weight: 600;
    font-size: 1.2vw;
}

.signup-form-invalid-cpf-warning-img {
    width: 1.2vw;
    height: 1.2vw;
}

.signup-form-invalid-cpf-info {
    color: #EB1B21;
    opacity: 0.95;
    font-size: 1.32vw;
    text-align: center;
    font-weight: 500;
}

.signup-form-invalid-cpf-button {
    font-weight: 600;
    width: fit-content;
    font-size: 1.36vw;
    color: #FFFFFF;
    cursor: pointer;
    transition: all .2s ease-out;
    background-color: #EB1B21;
    padding: 4px 32px;
    border-radius: 6px;
}

.signup-form-invalid-cpf-button:hover {
    filter: brightness(1.1);
    transform: scale(1.05);
}

.enter-here {
    font-weight: 500;
    cursor: pointer;
    background:
        linear-gradient(#EB1B21 0 0) var(--p, 0) 100% /var(--d, 0) 2px no-repeat;
    transition: 0.3s, background-position 0s 0.3s;
    font-weight: 600;
    text-decoration: underline;
}

.enter-here:hover {
    --d: 100%;
    --p: 100%;
}

.signup-form-inputs-container {
    width: 100%;
}

.signup-form-checkbox-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.promo-kv-signup {
    display: none;
}

@media (max-width: 600px) {

    .signup-form-row {
        flex-direction: column;
        gap: 6px;
    }

    .signup-form-title {
        font-size: 9vw;
    }

    .signup-form-subtitle {
        font-size: 5.2vw;
        margin-top: 0px;
    }

    .login-form-checkbox-container {
        font-size: 3vw;
    }

    .promo-kv-signup {
        display: flex;
        height: 15vh;
        margin: 2vh 0px 4vh 0px;
    }

    .signup-form-invalid-cpf-container {
        padding: 20px;
    }

    .signup-form-invalid-cpf-warning-container {
        padding: 6px 20px;
    }

    .signup-form-invalid-cpf-warning-text {
        font-size: 20px;
    }

    .signup-form-invalid-cpf-warning-img {
        width: 20px;
        height: 20px;
    }

    .signup-form-invalid-cpf-info {
        font-size: 22px;
    }

    .signup-form-invalid-cpf-button {
        font-size: 22px;
        padding: 6px 16px;
        border-radius: 8px;
    }

}

.signup-form-error-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 16pt;
    font-weight: 600;
    color: white;
    padding: 1rem;
}

.signup-form-error-container h3 {
    color: var(--color-red);
}

.signup-text{
    padding: 1rem;
    font-family: 'Archivo';
    font-size: 16pt;
    text-align: center;
}

.signup-container .checkbox-text{
    color: black;
    font-weight: 500;
}

.signup-container .checkbox-warning{
    color: var(--color-red);
}