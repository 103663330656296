.default-loader {
    width: 32px;
    height: 32px;
    border: 5px solid #da291c;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.linear-loader, .linear-loader:before, .linear-loader:after {
    border-radius: 50%;
    width: 1.5em;
    height: 1.5em;
    animation-fill-mode: both;
    animation: bblFadInOut 1.8s infinite ease-in-out;
}

.linear-loader {
    color: #da291c;
    font-size: 7px;
    position: relative;
    text-indent: -9999em;
    transform: translateZ(0);
    animation-delay: -0.16s;
    margin-bottom: 24px;
}

.white {
    color: #FFFFFF !important;
    margin-bottom: 0px;
    margin-top: -30px
}

.linear-loader:before,
.linear-loader:after {
    content: '';
    position: absolute;
    top: 0;
}

.linear-loader:before {
    left: -2.5em;
    animation-delay: -0.32s;
}

.linear-loader:after {
    left: 2.5em;
}
  
@keyframes bblFadInOut {
    0%, 80%, 100% { box-shadow: 0 2.5em 0 -1.3em }
    40% { box-shadow: 0 2.5em 0 0 }
}
      