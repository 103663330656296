.log-table {
    border-radius: 20px;
    background-color: #FFFFFF;
    width: 100%;
    border-spacing: 0;
    margin-bottom: 60px;
}

.log-table th {
    padding: 16px 24px;
    font-size: 1vw;
    font-family: 'Rubik', sans-serif;
    font-weight: 600;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1)
}

.log-table td {
    padding: 18px 6px;
    text-align: center;
    font-family: 'Rubik', sans-serif;
}

.log-table tr {
    border-radius: 10px;
    font-size: 1vw;
}

.log-table tbody tr:hover {
    background-color: #fbfbfb;
}

.log-table td:first-child {
    border-top-left-radius: 10px; 
    border-bottom-left-radius: 10px;
}

.log-table td:last-child {
    border-bottom-right-radius: 10px; 
    border-top-right-radius: 10px;
}

.logs-table-pagination-container {
    display: flex;
    gap: 18px;
    align-items: center;
    margin: -28px 0px 12px 0px;
}

.logs-table-page {
    font-family: 'Rubik', sans-serif;
    font-size: 20px;
}