.faq {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
}

.faq-item{
    border-radius: 15px 15px 15px 15px;
    overflow: hidden;
    background-color: #f4f4f4;
    padding: 20px;
    border: 1px solid #d9d9d9;

    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-duration: .2s;
    animation-duration: .2s;
    cursor: pointer;
}

.faq-item .faq-item_title {
    position: relative;
    padding-right: 16px;
}
.faq-item .faq-item_title:before {
    color: white;
    font-weight: 400;
    content: "+";
    right: 0;
    left: auto;
    position: absolute;
    font-size: 16px;
    top: 50%;
    margin-top: -.5em;
    background-color: black;
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    line-height: 1em;
    padding-bottom: 1px;
    
}

.faq-item .faq-item_content {
    padding-top: 20px;
    position: relative;
    display: none;
    line-height: 18pt
}

.faq-item.open {
    background-color: #fff;
}

.faq-item.open .faq-item_title:before{
    content: "-";
}

.faq-item.open .faq-item_content {
    display: inline-block;
}

.faq-footer{
    margin-top: 1rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.contact-icon{
    max-width: 20px;
}

.contact-line{
    display: flex;
    gap: 1rem;
    align-items: center;
}