.tos-page-container {
    display: flex;
    flex-direction: column;
    width: 100vw;
    padding-top: 1rem;
    gap: 1rem;
}

.pdf{
    border-top: 2px dashed var(--color-yellow);
    overflow-x: auto;
    overflow-y: hidden;
}