.footer-container {
    background-color: #EC1C24;
    box-shadow: 0px -4px 18px #00000029;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    display: flex;
    padding: 2rem 1rem;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    font-family: 'Archivo', sans-serif;
    position: relative;
    z-index: 5;
}

.footer-links {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    width: 100%;
    padding: 0 1rem;
}

.footer-links a {
    color: #FFFFFF;
    font-size: 20px;
}

.footer-links a:first-of-type {
    font-weight: 600;
}

.footer-social {
    display: flex;
    gap: 24px;
    margin-top: 30px;
}

.footer-social a img {
    width: 30px;
    cursor: pointer;
}

.footer-burocracy p {
    color: #FFFFFF;
    text-align: center;
    font-size: 10px;
    line-height: 14px;
}

.footer-logo-container {
    width: 80px;
}

.footer-logo {
    width: 100%;
    cursor: pointer;
}

.footer-divider {
    width: 75%;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom-color: #fff;
    border-bottom-width: 2px;
    opacity: 50%;
    outline: none;
}