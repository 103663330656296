.file-input-container-admin .file-input-container-admin input[type="file"] {
    outline: none;
    padding: 4px;
    margin: -4px;
    font-family: 'Rubik', sans-serif;
}

.file-input-container-admin input[type="file"]::before {
    top: 14px;
}
  
.file-input-container-admin input[type="file"]::after {
    top: 11px;
}
  
.file-input-container-admin input[type="file"] {
    position: relative;
    font-size: 1.1vw;
    width: 640px;
    font-family: 'Rubik', sans-serif;
}
  
.file-input-container-admin input[type="file"]::file-selector-button {
    width: 320px;
    color: transparent;
}

.file-input-container-admin input[type="file"]::before {
    position: absolute;
    pointer-events: none;
    left: 64px;
    color: #DA291C;
    content: "Escolha um arquivo";
    font-size: 20px;
    font-weight: 500;
    font-family: 'Rubik', sans-serif;
}
  
.file-input-container-admin input[type="file"]::after {
    position: absolute;
    pointer-events: none;
    left: 28px;
    top: 15px;
    height: 20px;
    width: 20px;
    content: "";
    background-image: url('../../../assets/icons/red-upload.png');
    background-size: cover;
}
  
.file-input-container-admin input[type="file"]::file-selector-button {
    border-radius: 8px;
    padding: 0 16px;
    height: 50px;
    cursor: pointer;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.16);
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
    margin-right: 16px;
    transition: background-color 200ms;
}
  

.file-input-container-admin input[type="file"]::file-selector-button:hover {
    background-color: #f3f4f6;
}

@media (max-width: 600px) {

}