.address-card {
    width: calc(48vw - 104px);
    height: fit-content;
    background-color: #FFFFFF;
    border-radius: 18px;
    padding: 18px 32px;
}

.address-form {
    width: 100%;
}

.address-form-cep-container {
    width: 70%;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}

.address-form-search-cep {
    color: #d0342c;
    text-decoration: underline;
    cursor: pointer;
    margin-left: 30px;
    font-size: 20px;
}

.address-upper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    align-items: center;
}

.address-title {
    font-size: 36px;
    font-weight: 600;
}

.address-edit-icon {
    height: 30px;
    cursor: pointer;
}

.address-edit-icon:hover {
    opacity: 0.6;
}

.address-form-line {
    width: 100%;
    height: 1px;
    background-color: #D3D3D3;
    margin: 20px 0px 32px 0px;
}

.address-form-row {
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 24px;
}

.address-form-buttons-container {
    display: flex;
    gap: 32px;
    margin: 10px 0px;
    flex-direction: row-reverse;
}

.address-form-buttons-container {
    display: flex;
    gap: 32px;
    margin-bottom: 10px;
    flex-direction: row-reverse;
}

.address-form-cancel-button {
    padding: 10px 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    font-size: 1.2rem;
    background-color: #D3D3D3;
    cursor: pointer;
    border: 1px solid #D3D3D3;
}

.address-form-save-button {
    padding: 10px 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    font-size: 1.2rem;
    background-color: #DA291C;
    cursor: pointer;
    border: 1px solid #DA291C;
    color: #FFFFFF;
    font-weight: 500;
    transition: all 0.2s ease-in-out;
}

.address-form-save-button:hover {
    background-color: #FFFFFF;
    color: #DA291C;
}

@media (max-width: 600px) {

    .address-title {
        font-size: 32px
    }

    .address-card {
        width: 100%;
    }

    .address-form-row {
        flex-direction: column;
        
    }

    .address-form-buttons-container {
        display: flex;
        gap: 32px;
        margin-bottom: 10px;
        flex-direction: row-reverse;
        justify-content: space-between;
    }

    .address-form-cancel-button {
        padding: 10px 6vw;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        font-size: 5vw;
        background-color: #D3D3D3;
        cursor: pointer;
        border: 1px solid #D3D3D3;
    }
    
    .address-form-save-button {
        padding: 10px 6vw;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        font-size: 5vw;
        background-color: #DA291C;
        cursor: pointer;
        border: 1px solid #DA291C;
        color: #FFFFFF;
        font-weight: 500;
        transition: all 0.2s ease-in-out;
    }
    
}