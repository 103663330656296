.home-greet {
    font-weight: 500;
}

.home-greet-container {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 1rem;
}

.home-hero{
    background-color: var(--color-orange);
    height: calc(100vh + 100px);
    padding-bottom: 150px;
    position: relative;
    overflow: hidden;
    z-index: 1;
    isolation: isolate;
    display: flex;
    align-items: center;
    justify-content: center;
}

.home-hero img{
    top: -100px;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    width: 100%;
    z-index: 1;
}

.home-hero .cta, .home-hero .title {
    z-index: 2;
    position: absolute;
    top: calc(100vh - 200px);
}

.home-how-to {
    background-color: white;
    margin: var(--spacing-1);
    padding: var(--spacing-2) var(--spacing-1);
    margin-top: -140px;
    padding-bottom: 6rem;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    position: relative;
    z-index: 2;
}

.home-how-to:first-child{
    margin-top: 0;
}

.home-how-to .home-how-to-card {
    border: 2px solid black;
    border-radius: 30px;
    padding: var(--spacing-2) var(--spacing-1);
    padding-top: var(--spacing-3);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.home-how-to .home-how-to-card .home-how-to-card-number{
    font-size: 28pt;
    position: absolute;
    left: calc(50% - 28px);
    top: -30px;
    border: 3px solid white;
    line-height: 24px;
}

.home-prizes{
    display: flex;
    flex-direction: column;
    background-color: var(--color-orange);
    background: linear-gradient(180deg, var(--color-orange) 0%, var(--color-red) 100%);
    gap: 2rem;
    z-index: 3;
}

.home-final-prize{
    background-color: var(--color-yellow);
    z-index: 4;
}

.home-final-prize img {
    margin-bottom: -2.5rem;
    width: 100%;
}

.home-final-prize .home-final-prize-card{
    display: flex;
    flex-direction: column;
    background-color: var(--color-orange);
    background: linear-gradient(180deg, var(--color-orange) 0%, var(--color-red) 100%);
    border-radius: 30px;
    padding: 2rem;
    gap: 1.5rem;
}

.home-chances{
    background-color: var(--color-orange);
    padding-bottom: 2rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    z-index: 5;
    margin-bottom: 2rem;
}

.home-footer {
    padding: 0;
}

.client_id{
    display: inline-block;
    padding: 1rem;
}