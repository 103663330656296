.admin-sidebar-container {
    width: 340px;
    height: 100vh;
    position: fixed;
    background-color: #EB1B21;
    padding: 28px;
}

.admin-sidebar-dropdown-title {
    color: #FFFFFF;
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 4px;
    font-family: 'Rubik', sans-serif;
}

.admin-sidebar-dropdown {
    width: 100%;
    height: 46px;
    background-color: #FFFFFF;
    border-radius: 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 22px;
    justify-content: space-between;
    margin-bottom: 14px;
}

.admin-sidebar-dropdown p {
    font-size: 18px;
    font-family: 'Rubik', sans-serif;
}

.admin-sidebar-dropdown-arrow {
    width: 14px;
}

.admin-sidebar-add-container {
    display: flex;
    align-items: center;
    gap: 16px;
    height: 6vh;
    cursor: pointer;
    padding: 0px 18px;
    border-radius: 12px;
}

.admin-sidebar-add-container:hover {
    background-color: #511628;
}

.admin-sidebar-add-icon {
    width: 20px;
}

.admin-sidebar-line {
    width: 100%;
    height: 1px;
    background-color: #FFFFFF;
    margin: 8px 0px;
}

.admin-sidebar-links-container {
    margin-top: 12px;
    height: calc(62vh);
    overflow-y: auto;
}

.admin-sidebar-links-container::-webkit-scrollbar {
    display: none;
  }
  

.admin-sidebar-link-container {
    display: flex;
    align-items: center;
    height: 60px;
    width: 100%;
    gap: 1rem;
    margin-bottom: 8px;
    border-radius: 12px;
    padding: 0px 18px;
    cursor: pointer;
}

.selected-link-container {
    background-color: #511628;
}

.admin-sidebar-link-container:hover {
    background-color: #511628;
}

.admin-sidebar-link-text {
    font-size: 1.2rem;
    color: #FFFFFF;
    letter-spacing: 0.6px;
    font-family: 'Rubik', sans-serif;
}

.selected-link-text {
    font-weight: 600;
}

.admin-sidebar-logout-container {
    display: flex;
    align-items: center;
    height: 48px;
    gap: 20px;
    padding: 0px 10px;
    cursor: pointer;
    position: absolute;
    bottom: 32px
}

.admin-sidebar-link-icon {
    --icon-size: 35px;
    height: var(--icon-size);
    width: var(--icon-size);
    min-width: var(--icon-size);
    min-height: var(--icon-size);
}