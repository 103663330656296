.prizes-background {
    width: 100vw;
    min-height: calc(100vh + 2rem);
    margin-bottom: -2rem;
    padding: 1rem;
    gap: 2rem;
    display: grid;
    grid-template-rows: auto 1fr;
    position: relative;
}

.toby-tomada {
    position: absolute;
    width: 390px;
    top: 196px;
    left: -172px;
}
.prizes-to-withdraw-title {
    font-size: 42px;
    font-weight: 500;
    margin-bottom: 10px;
}

.prizes-to-withdraw-subtitle {
    font-size: 1.8rem;
}

.prizes-to-withdraw-item {
    margin: 8px 0px 8px 20px;
    font-size: 1.4rem;
}

.item-color {
    color: #da291c;
    font-weight: 700;
    margin-right: 10px;
}

.item-verb {
    letter-spacing: 0.7px;
    font-weight: 500;
}

#pointer-text{
    cursor: pointer;
}

.download-color{
    color: black;
}

.prizes-to-withdraw-disclaimer {
    font-size: 1.8rem;
    margin-bottom: 60px;
}

.sub-text{
    text-decoration: underline;
    color: #da291c;
    cursor: pointer;
}

.prizes-to-withdraw-container {
    display: inline-flex;
    flex-direction: column;
    gap: 32px;
    margin-bottom: 60px;
}

.card-prize-container {
    background-color: #EB1B21;
    display: inline-block;
    padding: 18px 30px;
    border-radius: 20px;
    color: #FFFFFF;
    cursor: pointer;
    font-size: 1.1rem;
    transition: all 0.1s linear;
    font-weight: 500;
}

.card-prize-container:hover {
    background-color: rgb(101, 28, 50, 0.9);
    transform: scale(1.01)
}

.card-prize-prize {
    font-size: 1.4rem;
    letter-spacing: 0.7px;
}

.prizes-history-title {
    font-size: 2rem;
    font-weight: 500;
}

.prizes-table {
    border-radius: 20px;
    background-color: #FFFFFF;
    margin-top: 20px;
    width: 70vw;
    border-spacing: 0;
}

.prizes-table th {
    padding: 20px 32px;
    font-size: 1.2vw;
    font-weight: 600;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1)
}

.prizes-table td {
    padding: 18px 38px;
    text-align: center;
}

.prizes-table tr {
    border-radius: 10px;
    font-size: 1.025vw;
    letter-spacing: 0.5px;
}

.prizes-table tbody tr:hover {
    background-color: #fbfbfb;
}

.prizes-table-luck-number {
    letter-spacing: 0.8px;
    font-size: 1.06rem;
}

.prizes-table td:first-child {
    border-top-left-radius: 10px; 
    border-bottom-left-radius: 10px;
}
.prizes-table td:last-child {
    border-bottom-right-radius: 10px; 
    border-top-right-radius: 10px; 
}

.winner-prize{
    color: #da291c;
    font-weight: 600;
    margin-left: 5%;

}

@media only screen and (max-width: 600px) {

    .prizes-title-container {
        background-color: #EB1B21;
        padding: 0.6vw 2vw;
        color: #FFFFFF;
        border-radius: 15px;
        z-index: 4;
        width: fit-content;
        margin-bottom: 4vh;
    }

    .prizes-title {
        font-weight: 600;
        font-size: 10vw;
        letter-spacing: 0.6px;
        z-index: 4;
    }

    .prizes-title-mobile {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
    }

    .card-prize-container {
        padding: 12px 20px;
        font-size: 1rem;
        width: 80vw;
        text-align: center;
    }
    
    .toby-tomada {
        display: none;
    }

    .prizes-to-withdraw-title {
        font-size: 24px;
        font-weight: 600;
        width: 100%;
        text-align: center;
    }

    .prizes-to-withdraw-subtitle {
        font-size: 1.5rem;
        width: 100%;
        text-align: center;
        margin-bottom: 20px;
    }

    .prizes-to-withdraw-item {
        align-self: flex-start;
    }
    
    .prizes-to-withdraw-disclaimer {
        width: 100%;
        margin-top: 20px;
        text-align: center;
    }

    .prizes-history-title {
        font-size: 1.6rem
    }

    .prizes-history-subtitle {
        font-size: 1.5rem;
    }

    .prizes-table th {
        padding: 18px 16px;
        font-size: 4.8vw;
    }
    
    .prizes-table td {
        padding: 12px 16px;
        text-align: center;
        font-size: 4vw;
    }
}

.prizes-table-container {
    padding: 2rem 1rem;
    border-radius: 30px 30px 0 0;
    background-color: var(--color-yellow);
    margin: 0 -1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.tracking {
    font-weight: bold;
    transition: all 200ms;
    display: inline-block;
    max-height: 1rem;
    height: 1rem;
    display: flex;
    align-items: center;
    margin-top: 1rem;
}

.tracking.copied {
    color: var(--color-yellow);
}

.tracking .tooltip,
.tracking .hint {
    display: none;
    background-color: var(--color-yellow);
    color: var(--color-orange);
    border-radius: 3px;
    padding: .2rem;
    position: relative;
    margin-left: .5rem;
}

.tracking .tooltip::before,
.tracking .hint::before{
    display: block;
    content: '';
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: 7px solid var(--color-yellow);
    position: absolute;
    left: -7px;
    top: 5px;
}

.tracking.copied .tooltip{
    display: inline-block;
}

.tracking.hinted .hint {
    display: inline-block;
    animation: hint 2s infinite;
}



@keyframes hint {
    0% {
        transform: translateX(0px);
    }
    50% {
        transform: translateX(5px)
    }
    100% {
        transform: translateX(0px)
    }
}