.search-bar-container {
    display: flex;
    position: relative;
    align-items: center;
}

.search-icon {
    width: 18px;
    height: 18px;
    position: absolute;
    margin-left: 16px;
}

.search-bar-input {
    width: 18vw;
    border: 0;
    padding: 12px 10px 12px 46px;
    border-radius: 20px;
    font-size: 1vw;
    border: 1px solid #D3D3D3;
    letter-spacing: 0.6px;
}

.cancel-icon {
    width: 14px;
    height: 14px;
    position: absolute;
    margin-left: calc(18vw - 18px - 10px);
    cursor: pointer;
}

.text-input-component .eye-icon {
    position: absolute;
    width: 26px;
    bottom: 33px;
    right: 22px;
    cursor: pointer;
    transition: all .2s ease-out;
}

.text-input-component .eye-icon:hover {
    filter: brightness(80%);
}

@media only screen and (max-width: 600px) {
    .search-bar-input {
        width: 100%;
        font-size: 5vw;
    }
}

.text-input-component {
    position: relative;
    width: 100%;
}

.text-input-component .label {
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.8px;
    margin-bottom: 3px;
    text-align: start;
    color: #FFFFFF;
}

.text-input-component .label-container {
    display: flex;
    justify-content: space-between;
}

.text-input-component .forgot {
    color: #EB1B21;
    font-weight: 500;
    cursor: pointer;
}

.text-input-component .input {
    display: flex;
    align-items: center;
    width: 100%;
    height: 46px;
    border-radius: 10px;
    outline: none;
    border: 1px solid #D3D3D3;
    padding: 0px 58px 0px 20px;
    font-size: 21px;
    letter-spacing: 1px;
    transition: all 0.3s cubic-bezier(0, .6, 1, .6);
    background-color: #FFFFFF;
}

.text-input-component .input::placeholder {
    letter-spacing: 0.5px;
    opacity: 0.8;
}

.text-input-component .valid {
    border: 1px solid #FBE1E0;
    transition: all 0.3s cubic-bezier(0, .6, 1, .6);
}

.text-input-component .valid:focus {
    box-shadow: 0px 1px 1px 0px rgba(255,255,255,0.11),
    0px 2px 2px 0px rgba(255,255,205,0.11),
    0px 4px 4px 0px rgba(255,255,255,0.11),
    0px 6px 8px 0px rgba(255,255,255,0.11),
    0px 8px 16px 0px rgba(255,255,255,0.11);
    transition: 0.15s;
}

.text-input-component .invalid {
    border: 1px solid #FF5F5F;
    transition: 0.2s;
}

.text-input-component .invalid:focus {
    box-shadow: 0px 1px 1px 1px;
    box-shadow: 0 1px 3px rgba(255,95,95,1), 0 1px 2px rgba(255,95,95,1);
    border: 1px solid #FF5F5F;
    transition: .2s;
}

.text-input-component .invalid-black {
    border: 1px solid #FF5F5F;
    transition: 0.2s;
}

.text-input-component .disabled {
    opacity: 0.8;
    border: 1px solid #D3D3D3;
}

.rubik p,
.rubik input {
    font-family: 'Rubik', sans-serif;
}

.text-input-component .warning-message {
    display: flex;
    align-items: center;
    height: 16px;
    margin-bottom: 8px;
    font-size: 14px;
    color: #FBE1E0;
}

@media only screen and (max-width: 600px) {
    .text-input-component .label {
        margin-bottom: 5px;
    }

    .text-input-component .input {
        aspect-ratio: 90/14;
        font-size: 4.8vw;
        padding: 0px 52px 0px 20px;
    }

    .text-input-component .eye-icon {
        width: calc(0.08148 * 100%);
        bottom: 34px;
        right: 20px;
    }

    .text-input-component .warning-message {
        height: 20px;
    }

    .text-input-component .fixed {
        padding: 0px 48px 0px 156px;
    }

    .text-input-component .input-prefix {
        position: absolute;
        left: 20px;
        top: 49px
    }

    .loader {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        position: absolute;
        right: 23px;
        bottom: 36px;
        animation: rotate 1s linear infinite
    }
    
}