.status-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
    border-radius: 8px;
}

.status-not-done {
    background-color: #cfcfc4;
    color: #404040
}

.status-done {
    background-color: #77DD76;
    border: 1px solid #39cd37;
    color: 	#023020
}

.status-text {
    font-family: "Rubik", sans-serif;
    font-size: 0.95vw;
}