.popup-address-container {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    border-radius: 16px;
    padding: 24px 40px;
    width: 50vw;
}

.popup-address-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding-bottom: 10px;
    margin-bottom: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
}

.popup-address-header p {
    font-size: 30px;
    font-family: 'Rubik', sans-serif;
    text-transform: uppercase;
    font-weight: 600;
}

.popup-address-row {
    display: flex;
    gap: 10px;
    margin: 10px 0px;
    justify-self: flex-start;
}

.popup-address-item, .popup-address-info {
    font-size: 20px;
    font-family: 'Rubik', sans-serif;
}

.popup-address-item {
    font-weight: 600;
}