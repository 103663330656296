.draw-table {
    border-radius: 20px;
    background-color: #FFFFFF;
    width: 100%;
    border-spacing: 0;
}

.draw-table th {
    padding: 16px 24px;
    font-size: 1.1vw;
    font-family: 'Rubik', sans-serif;
    font-weight: 600;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1)
}

.draw-table td {
    padding: 18px 6px;
    text-align: center;
    font-family: 'Rubik', sans-serif;
}

.draw-table tr {
    border-radius: 10px;
    font-size: 1.08vw;
}

.draw-table tbody tr:hover {
    background-color: #fbfbfb;
}

.draw-table td:first-child {
    border-top-left-radius: 10px; 
    border-bottom-left-radius: 10px;
}

.draw-table td:last-child {
    border-bottom-right-radius: 10px; 
    border-top-right-radius: 10px;
    display: flex;
    gap: 20px;
    padding: 16px 12px 16px 32px;
    align-items: center;
}

.table-action-icon {
    width: 24px;
    cursor: pointer;
}

.table-action-icon:hover {
    filter: brightness(2);
}

.table-action-icon-disabled {
    width: 24px;
    cursor: default;
    opacity: 0.4;
}

.draw-table-period-container {
    display: flex;
    gap: 10px;
    justify-content: center;
}

.draw-table-period-container p {
    font-family: "Rubik", sans-serif;
}