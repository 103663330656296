.checkbox-container {
    display: flex;
    gap: 12px;
}

[type=checkbox] {
    width: 20px;
    height: 20px;
    color: black;
    vertical-align: middle;
    -webkit-appearance: none;
    background: none;
    border: 0;
    outline: 0;
    flex-grow: 0;
    border-radius: 50%;
    background-color: #FFFFFF;
    transition: background 100ms;
    cursor: pointer;
}

[type=checkbox]::before {
    content: "";
    color: transparent;
    display: block;
    width: inherit;
    height: inherit;
    border-radius: inherit;
    border: 0;
    background-color: transparent;
    background-size: contain;
    box-shadow: inset 0 0 0 1px #CCD3D8;
}

[type=checkbox]:checked {
    background-color: currentcolor;
}
  
[type=checkbox]:checked::before {
    box-shadow: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E %3Cpath d='M15.88 8.29L10 14.17l-1.88-1.88a.996.996 0 1 0-1.41 1.41l2.59 2.59c.39.39 1.02.39 1.41 0L17.3 9.7a.996.996 0 0 0 0-1.41c-.39-.39-1.03-.39-1.42 0z' fill='%23fff'/%3E %3C/svg%3E");
}
  
[type=checkbox]:disabled {
    background-color: #CCD3D8;
    opacity: 0.84;
    cursor: not-allowed;
}

.checkbox-text-container {
    margin-top: 4px;
    cursor: pointer;
}
  
.checkbox-text {
    color: #FFFFFF;
    font-weight: 200;
    font-size: 13px;
}

.checkbox-warning {
    text-transform: uppercase;
    font-weight: 700;
    margin-top: 1rem;
    display: block;
}