:root{
    --spacing-1: 1.5rem;
    --spacing-2: 2rem;
    --spacing-3: 3rem;
    --border-radius: 30px;
    --color-yellow: #F0C855;
    --color-orange: #FC5924;
    --color-red: #EB1B21;
}

body.overlay{
    overflow-y: hidden !important;
}

.title{
    font-size: 20pt;
    text-shadow: 
    1px 1px black, -1px -1px black, 1px -1px black, -1px 1px black,
    4px 3px black,3px 4px black;
    text-align: center;
    font-family: 'Archivo Black';
}

.text {
    font-size: 16pt;
    text-align: center;
    font-family: 'Archivo';
}

.big-text{
    font-size: 18pt;
}

.text-yellow {
    color: var(--color-yellow);
}

.text-white {
    color: white;
}

.image-card {
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 2px solid black;
    border-radius: 40px;
    padding:  var(--spacing-2) var(--spacing-1);
    gap: 1.5rem;
    background-color: var(--color-yellow);
}

.image-card > img{
    margin: calc(calc(var(--spacing-2) * -1) - 2px) calc(calc(var(--spacing-1) * -1) - 2px) 0 calc(calc(var(--spacing-1) * -1) - 2px);
    border: 2px solid;
    border-radius: 40px;
    object-fit: cover;
    border-color: black;
    background-color: black;
}

.image-card > img.white-border{
    border: 2px solid white;
    background-color: white;
}


.home-section {
    border-radius: 30px;
    margin-top: -50px;
    padding: var(--spacing-1);
    padding-bottom: calc(50px + var(--spacing-1));
    position: relative;
}

.chat-bubble {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    padding: .8rem;
    border-radius: 50%;
    background-color: var(--color-yellow);
    position: relative;
    isolation: isolate;
}

.chat-bubble img{
    width: 100%;
}

.chat-bubble::before{
    display: block;
    content: '';
    position: absolute;
    bottom: 5px;
    left: -20px;
    z-index: -1;
    width: 36px; 
    height: 16px; 
    transform: rotate(305deg) skew(55deg);
    transform-origin: top right;
    border-radius: 60px 50px 10px 10px ;
    background-color: var(--color-yellow); 
}

.chat-bubble.chat-bubble-orange {
    background-color: var(--color-orange);
    background: linear-gradient(180deg, var(--color-orange) 0%, var(--color-red) 100%);
}

.chat-bubble.chat-bubble-orange::before{
    background-color: var(--color-red);
}

.cta {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: black;
    color: white;
    height: 50px;
    border-radius: 30px;
    position: relative;
    font-family: 'Archivo';
    font-weight: 600;
    font-size: 16pt;
    padding: 0 2rem;
    border: none;
    outline: none;
}

.cta:disabled {
    background: #959090;
}


.card-list{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.card-list .card {
    display: flex;
    flex-direction: column;
    background-color: var(--color-orange);
    border-radius: 30px;
    color: white;
    padding: 1rem;
    gap: 1rem;
}

.card-list .card .card-content{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.page-container{
    margin-top: 1rem;
}

.overlay-bg {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    min-height: 100vh;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    padding: 90px 0px 60px 0px;
    overflow-x: hidden;
    z-index: 600;
}