.status {
    height: 28px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    border-radius: 6px;
    padding: 0 .5rem;
}

.status-expirado {
    background-color: #FF6962;
    opacity: 0.3;
}

.status-analise {
    background-color: #A7C7E7;
}

.status-pendente {
    background-color: #DAFAE9;
}

.status-aguardando {
    background-color: #cfcfc4;
}

.status-sorteado {
    background-color: #77DD76;
    border: 1px solid #39cd37
}

.status-resgatar {
    background-color: #EB1B21;
    color: #FFFFFF;
    cursor: pointer;
    transition: all 0.1s linear;
}

.status-resgatar:hover {
    transform: scale(1.04);
}
